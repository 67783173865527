import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import { MsgInfoService } from './services/msg-info.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'project';
  constructor(
      private _snackBar: MatSnackBar,
      private msgInfo : MsgInfoService
    ) {}
  
  
  openSnackBar(message: string, action: string, duration: number) {
    this._snackBar.open(message, action,{
      duration: duration
    });
  }
  
  closeSnackBar() {
    this._snackBar.dismiss();
  }

  ngOnInit(): void {
    this.msgInfo.subscribeInfo().subscribe((res:any)=>{
      if (res && res.msg){
        this.openSnackBar(res.msg, '', res.duration)
      }
      if(res && !res.msg){
        this.closeSnackBar();
      }
    })
  }
}
