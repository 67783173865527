import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { AuthenticationService } from "src/app/services/authentication.service";
import { Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { MsgInfoService } from "src/app/services/msg-info.service";
import { environment } from 'src/environments/environment';

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted: boolean;
  error: string;
  password2:string;
  showPassword = false;
  
  constructor(
    private fb: FormBuilder,
    private authService: AuthenticationService,
    private router: Router,
    private msgInfo: MsgInfoService,
  ) {
    this.submitted = false;
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required]
    });
    this.error = '';
    this.password2 = '';
  }
  
  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    
    this.msgInfo.updateInfo({msg:"Connexion en cours", duration : 99999999});
    
    this.authService
      .login(this.loginForm.value.email, this.loginForm.value.password)
      .subscribe(
        (data:any) => {
          if (data){
            const token = data.token;
            const decoded:any = jwtDecode(token);
            sessionStorage.setItem("USER-knu21", token);            
            if (decoded["Role"]) {
              
              if(decoded["Role"] == "SURVEILLANT_"+environment.establishment || decoded["Role"] == "CPE_"+environment.establishment){
                this.router.navigate(["staff"]);
                this.msgInfo.updateInfo({msg: null, duration :"1"});
              }
            }
          }
        },
        (err:any) => {
          sessionStorage.removeItem("USER-knu21");
          this.msgInfo.updateInfo({msg:"La connexion a échoué", duration : 1500});
          this.error = err;
        }
      );
  }

  ngOnInit() {
   
  }
}
