
  <div class ="tab-container">

      
      <div class="flex-container mb20">
        <div>
          <div class="mat-mini-icon-container" (click)="openMessagerie()">
            <mat-icon>send</mat-icon>
            <span> Nouveau message</span>
          </div>
        </div>
        
        <div class="flex-container" >
          <mat-form-field  class="formfieldSearch">
            <mat-label>Rechercher</mat-label>
            <input matInput [(ngModel)]="searchedTerm" (keyup.enter)="searchMessage()">
          </mat-form-field>

          <div class="mat-mini-button" (click)="searchMessage()">
            <mat-icon>search</mat-icon>
          </div>
        </div>
      </div>

    <mat-tab-group>
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">chat_bubble</mat-icon>
            Non Lus 
            <span class="infonb" *ngIf="data_noread && data_noread.length > 0"> ({{data_noread.length}})</span>
          </ng-template>
          <div class="table-container">
            <app-table-staff 
            [data]="data_noread" 
            [displayedColumns]="columns_noread" 
            [type]="'MsgNoRead'"
            (tableChanged)="modify($event)"
            ></app-table-staff>
        </div>
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">chat_bubble_outline</mat-icon>
            Lus
            <span class="infonb" *ngIf="data_recep && data_recep.length > 0"> ({{data_recep.length}})</span>
          
          </ng-template>
          <div class="table-container">
              <app-table-staff 
              [data]="data_recep" 
              [displayedColumns]="columns_recep" 
              [type]="'MsgRecep'"
              (tableChanged)="modify($event)"
              ></app-table-staff>
          </div>
        </mat-tab>
      
        <mat-tab>
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">unarchive</mat-icon>
            Envoyés 
            <span class="infonb" *ngIf="data_send && data_send.length > 0"> ({{data_send.length}})</span>

          </ng-template>
          <div class="table-container">
              <app-table-staff 
              [data]="data_send" 
              [displayedColumns]="columns_send" 
              [type]="'Msg'"
              (tableChanged)="modify($event)"
              ></app-table-staff>
          </div>
        </mat-tab>
      </mat-tab-group>
  </div>