<div class="dialog" *ngIf="data && data.details">
    <div class="title-container"> 
        <div class="class">
            {{inject.classe}}
        </div>
        <div class="time">
            <div class="date">{{inject.date}}</div>
            <div class="hour">{{inject.heure}}</div>
        </div>
    </div>
    <div class="students-container">
        <div class="table-container">
            <table>
                <thead>
                    <tr>
                        <th>Eleve</th>
                        <th>Présent <mat-slide-toggle [(ngModel)]="allPresent" (change)="setAllPresences(allPresent)"></mat-slide-toggle> </th>
                        <th>En retard <mat-slide-toggle [(ngModel)]="allRetard" (change)="setAllRetards(allRetard)"></mat-slide-toggle> </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="student" *ngFor="let el of data.details">
                        <td class="nom">
                            <div style="font-size: 16px;">{{el.student.name}}</div> 
                            <div  style="font-size: 12px;">{{el.student.firstname}}</div>
                        </td>
                        <td class="sliders">
                            <mat-slide-toggle [(ngModel)]="el.isPresent"></mat-slide-toggle>
                        </td>
                        <td>
                            <mat-slide-toggle [(ngModel)]="el.isRetarted"></mat-slide-toggle>
                        </td>
                    </tr>
                </tbody>
               </table>
 
        </div>    
        <div class="btn-container">
            <button class="btn" [mat-dialog-close]="data">Valider l'appel</button>
         </div>   
    </div>
</div>