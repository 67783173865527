import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NgFor, AsyncPipe} from '@angular/common';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-affectations-staff',
  templateUrl: './affectations-staff.component.html',
  styleUrls: ['./affectations-staff.component.css']
})
export class AffectationsStaffComponent {
  classes = new Array();
  students = new Array();
  affectation:any;
  data:any;
  displayedColumns:any;
  myControl = new FormControl('');
  filteredOptions:any;
  selectables :any = {
  };
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.students.filter(s => s.student.name.toLowerCase().includes(filterValue) || s.student.firstname.toLowerCase().includes(filterValue));
  }

  constructor(private dataService : DataStaffService){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
    if(sessionStorage.getItem('USER-knu21')){
      let token = sessionStorage.getItem('USER-knu21')+"";
      let decode:any = jwtDecode(token);
         
      
      this.dataService.getStaffAffectations(decode.id).subscribe((res:any)=>{
        console.log(res.data);
        for(let k in  res.data){
          if(res.data[k].structCode && this.classes.indexOf(res.data[k].structCode) == -1 ){
            this.classes.push(res.data[k].structCode);
          }
        }
        let display_data = this.displayDataAffectations(res.data);
        this.data = display_data[0];
        this.displayedColumns =display_data[1];
        this.displayedColumns.push('actions');  
        
        if(this.classes.length > 0 ){
          for (let k in this.classes) {
            
            this.dataService.getStudentsByClass(this.classes[k]).subscribe((res:any)=>{
              this.students = this.students.concat(res.data.data);
            })
          }
        }
        
      })
    }
  
  }
  
  displayDataAffectations(data:Array<any>){
    console.log(data);
    
    let return_data = new Array();
    let colums = new Array(
      'Début',
      'Fin',
      'Classe',
    );
    
    if(data){
      for(let k = 0; k < data.length ; k++){
          
          return_data.push({
            Début:  this.formatDate(data[k].beginDate,data[k].timeBegin),
            Fin:  this.formatDate(data[k].endDate,data[k].timeEnd),
            Salle: data[k].idRoomm ? data[k].idRoomm : '',
            Classe: data[k].structCode ? data[k].structCode : '',
            id: data[k].id,
            callDetail: data[k].call
          });
      }
      return_data.sort((a, b) => {
        if (a.Début < b.Début) {
            return -1;
        }
        if (a.Début > b.Début) {
            return 1;
        }
        return 0;
      });
    }    
    return new Array(return_data,colums);
  }

  formatDate(dateNumber:number, timeNumber:number) {
    const year = Math.floor(dateNumber / 10000);
    const month = Math.floor((dateNumber % 10000) / 100);
    const day = dateNumber % 100;

    const hours = Math.floor(timeNumber / 10000);
    const minutes = Math.floor((timeNumber % 10000) / 100);
    const seconds = timeNumber % 100;
    
    const formattedDate = `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
    const formattedTime = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;

    return formattedDate + " " + formattedTime;
  }
  formatDate2(str:string){
    str = str.replaceAll("/","");
    str = str.replaceAll(":","");
    
    return str.split(" ");
  }
}
