import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StaffGuard } from './helper/staff.guard';
import { StaffComponent } from './pages/staff/staff.component';
import { AffectationsStaffComponent } from './pages/staff/affectations-staff/affectations-staff.component';
import { StudentsStaffComponent } from './pages/staff/students-staff/students-staff.component';
import { LoginComponent } from './authentication/login/login.component';
import { MsgComponent } from './pages/staff/msg/msg.component';
const routes: Routes = [
  
 
  { path: 'staff', component : StaffComponent,canActivate: [StaffGuard], children :  [
    { path: 'affectations',  component : AffectationsStaffComponent, canActivate : [StaffGuard]},
    { path: 'students',  component : StudentsStaffComponent, canActivate : [StaffGuard]},
    { path: 'msg',  component : MsgComponent, canActivate : [StaffGuard]},
    { path: '', redirectTo: 'affectations' ,pathMatch: 'full' },
    { path: '**',  component : AffectationsStaffComponent, canActivate : [StaffGuard] },
    
  ]},
  
  
  { path: 'login', component: LoginComponent },
  { path: '**',  redirectTo: '/login', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
