import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['./form-dialog.component.css']
})
export class FormDialogComponent {
  selectables:any;
  default_data:any;
  ret_data:any = {};
  title:string;
  constructor(    
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
    this.selectables = this.data.data;
    this.default_data = this.data.default_data;
    this.title = this.data.title;
    for(let k in this.selectables){
      if (this.default_data && (this.default_data[k] || this.default_data[k] === false) ){
        this.ret_data[k+''] = this.default_data[k];
      }else if(this.selectables[k]==="boolean"){
        this.ret_data[k+''] = false;
      }
      else{
        this.ret_data[k+''] = <any>'';
      }
    }
    console.log(this.ret_data);
    
  };
  isString(str:any){
    return  typeof str === 'string' || str instanceof String;
  }
  checkDate(e:any, col:any, el:any){   
    console.log(e.key);
    
    if(e.key != "Backspace" && e.key !="ArrowLeft" && e.key !="ArrowRight"){
      let str = e.target.value.replace(/[^0-9]/g, '');
      e.target.value = str;
      e.target.value = this.formatDate(e.target.value);

    }
  }
  formatDate(str:string) {
      // Vérifier si la chaîne est de la longueur attendue

  
      // Extraire les parties de la date et de l'heure
      var annee = str.substr(0, 4);
      var mois = str.substr(4, 2);
      var jour = str.substr(6, 2);
      var heure = str.substr(8, 2);
      var minute = str.substr(10, 2);
      var seconde = str.substr(12, 2);
  
      // Retourner la chaîne formatée
      
      let ret = "";
      if(annee){
        ret += annee;
      }
      if(mois){
        ret += "/"+mois;
      }
      if(jour){
        ret += "/"+jour;
      }
      if(heure){
        ret += " "+heure;
      }
      if(minute){
        ret += ":"+minute;
      }
      if(seconde){
        ret += ":"+seconde;
      }
      return ret;
 
  }
  check(d:any){
    let bool = true;
    for(let k in d){
      if(d[k]=== ''){
        bool = false;
        break;
      }
    }
    return bool;
  }
  
  apply2End(s:any){
    if(s == "Début" && this.ret_data["Fin"] == ""){
      this.ret_data["Fin"] = this.ret_data["Début"];
    }
    if(s == "Fin" && this.ret_data["Début"] == ""){
      this.ret_data["Début"] = this.ret_data["Fin"];
    }
  }
}
  
