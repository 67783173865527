import { Component, ViewChild,SimpleChanges,OnChanges,Input,Output,EventEmitter } from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {jwtDecode} from 'jwt-decode';
import {MatDialog} from '@angular/material/dialog';
import { FormDialogComponent } from '../form-dialog/form-dialog.component';

@Component({
  selector: 'app-table-stu',
  templateUrl: './table-stu.component.html',
  styleUrls: ['./table-stu.component.css']
})
export class TableStuComponent {

  @ViewChild(MatSort) sort!: MatSort;
  @Input() displayedColumns!: any;
  @Input() data!: any;
  @Input() title!: string;
  @Input() type!: string;
  @Input() selectables!: any;
  @Input() typeTable!: any;
  @Output() tableChanged = new EventEmitter<any>();
  
  userId:any;
  dataSource: any;
  filter :any;
  fichierPDF : any;
  showMenu = false;
  hide_filter = true;
  constructor(
    private dialog:MatDialog
  ){
    if(sessionStorage.getItem('USER-knu21')){
      let token = sessionStorage.getItem('USER-knu21')+"";
      let decode:any = jwtDecode(token);
      this.userId = decode.id;   
    }
  }
   openPdfInNewTab(base64String: string) {
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/pdf' });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
  }
  
  private generateFilter(){
    this.filter = {};
    if(this.displayedColumns && this.displayedColumns.length>0){
      for(let i=0 ;i<this.displayedColumns.length;i++){
        if(this.displayedColumns[i] !="action" && this.displayedColumns[i]!="checked"){
          this.filter[this.displayedColumns[i]] = "";
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      this.generateFilter();
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();

    }

  }
  applyFilter(event: Event) {
    this.dataSource.filter = this.filter;
  }
  createFilter() {
    let filterFunction = function (data: any, filter:any): boolean {
      let isFilterSet = false;
      for (const col in filter) {
        if (filter[col].toString() !== '') {
          isFilterSet = true;
        }
      }
      
      
      let nameSearch = () => {
        let found = true;
        if (isFilterSet) {
          for (const col in filter) {
            if(col !="actions" && col !="checked"){
              filter[col].trim().toLowerCase().split(' ').forEach((word:any) => {
                if (!(data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet)) {
                  found = false
                }
              });
            }
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }
  onFileSelected(event: any, row:any) {
    this.fichierPDF = event.target.files[0];
    this.submitForm(row);
  }

  async submitForm(row:any) {
    if (this.fichierPDF) {
      try {
        const blobString = await this.pdfToBlob(this.fichierPDF);
        let params= {
          endDate:row.endDate,
          endHour:row.endHour,
          beginDate:row.beginDate,
          beginHour:row.beginHour,
          description : "no comment",
          documentJustificatif : blobString,
          idExcuse: row.idExcuse
        
        }
        this.tableChanged.emit({msg:'upload', data:params});
      } catch (error) {
        console.error('Erreur lors de la lecture du fichier PDF :', error);
      }
    } else {
      console.error('Aucun fichier PDF sélectionné.');
    }
  }

  private pdfToBlob(fichier: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      
      reader.onload = () => {
        const blobString = reader.result as string;
        resolve(blobString);
      };

      reader.onerror = () => {
        reject(reader.error);
      };

      reader.readAsDataURL(fichier);
    });
  }
  create() {
    const dialogRef = this.dialog.open(FormDialogComponent,{
      width: '350px',
      height: '80vh',
      data: {
        data : this.selectables,
        title : "Ajouter "+ this.type,
        type : "create"
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        this.tableChanged.emit({msg:'create', data:res});
      }
    });
  }
}
