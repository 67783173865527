import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-add-absence',
  templateUrl: './dialog-add-absence.component.html',
  styleUrls: ['./dialog-add-absence.component.css']
})
export class DialogAddAbsenceComponent {

}
