<mat-icon class="exitDialog" (click)="onNoClick()">clear</mat-icon>

<div class="flex-container">
    <div *ngIf="data && data.De">
        <div class="label"> Message de</div>
        <div class="info">{{data.De}}</div>
     </div>

     <div *ngIf="data && data.A">
        <div class="label"> Message à</div>
        <div class="info">{{data.A}}</div>
     </div>
     
     <div *ngIf="data && data.De">
        <div class="label"> Reçu à</div>
        <div class="info">{{data.Heure}}</div>
     </div>  
     
     <div *ngIf="data && data.A">
        <div class="label"> Envoyer à</div>
        <div class="info">{{data.Heure}}</div>
     </div>  
</div>

<div class="flex-container">
    <div class="subject">
        {{data.Sujet}}
    </div>
</div>

<div class="flex-container">
    <div class="body">
        {{data.Message}}
    </div>
</div>

<div *ngIf="data && data.De">
    <button class="btn" [mat-dialog-close]="{to : data.De, subject : data.Sujet}"> Répondre </button>
 </div>  
