import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagerieService {

  constructor( private http : HttpClient) { 

    
  
  
  }
  getSend(){
    return this.http.get(`${environment.apiUrl}/api/Message/senders`);
  }

  getRecep(){
    return this.http.get(`${environment.apiUrl}/api/Message/recep`);
  }
  
  getSearchTitle(title:string){
    return this.http.get(`${environment.apiUrl}/api/Message/title/`+title);
  }
  
  getSearchBody(body:string){
    return this.http.get(`${environment.apiUrl}/api/Message/body/`+body);
  }

  setRead(idMessage:string){
    return this.http.get(`${environment.apiUrl}/api/Message/read/`+idMessage);
  }
  
  send(id:string, data:any){
    return this.http.post(`${environment.apiUrl}/api/Message/send/`+id, data);
  }
}
