import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
@Component({
  selector: 'app-call-dialog-staff',
  templateUrl: './call-dialog-staff.component.html',
  styleUrls: ['./call-dialog-staff.component.css']
})
export class CallDialogStaffComponent {
  data:any;
  allRetard = false;
  allPresent = false;
  constructor(    
    private dataService : DataStaffService,
    @Inject(MAT_DIALOG_DATA) public inject: any
  ){
    console.log('dialog data',this.inject)
    
    this.dataService.getCall(this.inject.id).subscribe((res:any)=>{
      this.data = res.data[0];
      this.data.details.sort((a:any, b:any) => {
        if (a.student.name < b.student.name) {
            return -1;
        }
        if (a.student.name > b.student.name) {
            return 1;
        }
        return 0;
      });
      console.log(res);
      this.setAllPresences(false);
      this.setAllRetards(false);
    })
    
    
  }
  setAllPresences(bool:boolean){
    for(let k in this.data.details){
      this.data.details[k]['isPresent'] = bool;
    }
  }
  setAllRetards(bool:boolean){
    console.log(this.data.details);
    for(let k in this.data.details){
      this.data.details[k]['isRetarted'] = bool;
    }
  }
}
