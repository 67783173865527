import {Component, OnInit} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NgFor, AsyncPipe} from '@angular/common';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { jwtDecode } from "jwt-decode";
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-students-staff',
  templateUrl: './students-staff.component.html',
  styleUrls: ['./students-staff.component.css']
})
export class StudentsStaffComponent implements OnInit {
  
  classes = new Array();
  students = new Array();
  affectation:any;
  data:any;
  displayedColumns:any;
  selectables :any = {
  };
  constructor(
    private refreshService : RefreshService,
    private dataService : DataStaffService,
  ){
    
  
  }
  ngOnInit(): void {
      this.getData();
      this.refreshService.subscribeRefreshStudents().subscribe((res:any)=>{
        if(res){
          this.getData();
        }
      })
  }
  getData(){
    if(sessionStorage.getItem('USER-knu21')){
      let token = sessionStorage.getItem('USER-knu21')+"";
      let decode:any = jwtDecode(token);
      this.dataService.getStaffAffectations(decode.id).subscribe((res:any)=>{
        console.log(res);

        if(res){
          this.data = new Array()
          for(let k in  res.data.affectations){
            if(this.classes.indexOf(res.data.affectations[k].structCode)== -1 ){
              this.classes.push(res.data.affectations[k].structCode);
            }
          }
          if(this.classes.length > 0 ){
            for (let k in this.classes) {
              this.dataService.getStudentsByClass(this.classes[k]).subscribe((res:any)=>{
                this.data = this.data.concat(this.displayDataStudents(res.data)[0]);
                this.displayedColumns = this.displayDataStudents(res.data)[1];                
              })
            }    
          }
        }
        
      })
    }
  }
  displayDataStudents(data:any){
    let return_data = new Array();
    let colums = new Array(
      'Ine',
      'Nom',
      'Prénom',
      'Salle',
      'Remarques',
      'Absences',
      'actions'
    );
    
    if(data){
      for(let k = 0; k < data.students.length ; k++){
        return_data.push({
          Ine: data.students[k].ine,
          Nom: data.students[k].name,
          Prénom: data.students[k].firstName,
          Remarques: data.students[k].annotations.length,
          Absences:  data.students[k].excuses.length,
          annotations: data.students[k].annotations,
          excuses:  data.students[k].excuses,
          Classe: data.strucLabel,
          id: data.students[k].ine,
        });
      }
    }

    return new Array(return_data,colums);
  
  }
}
