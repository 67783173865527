import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-call-dialog-staff',
  templateUrl: './call-dialog-staff.component.html',
  styleUrls: ['./call-dialog-staff.component.css']
})
export class CallDialogStaffComponent {
  data:any;
  allRetard = false;
  allPresent = false;
  typeOrder = false;
  constructor(    
    private dataService : DataStaffService,
    @Inject(MAT_DIALOG_DATA) public inject: any,
    private msgInfo : MsgInfoService,
    public dialogRef: MatDialogRef<CallDialogStaffComponent>,

  ){
    
    this.dataService.getCall(this.inject.id).subscribe((res:any)=>{
      this.data = res.data[0];
      console.log(res.data);
      
      this.data.details.sort((a:any, b:any) => {
        if (a.student.name < b.student.name) {
            return -1;
        }
        if (a.student.name > b.student.name) {
            return 1;
        }
        return 0;
      });
      if(!this.data.isClose){
        this.setAllPresences(false);
        this.setAllRetards(false);
      }
      if(localStorage.getItem("KNU-21-call-order")){
        let el:any = localStorage.getItem("KNU-21-call-order");
        this.typeOrder = JSON.parse(el);
        this.setOrder();
      }
    },(error)=>{
      this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
    })
    
    
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  setAllPresences(bool:boolean){
    for(let k in this.data.details){
      this.data.details[k]['isPresent'] = bool;
    }
  }
  setOrder(){
    if(!this.typeOrder){
      this.data.details.sort((a:any, b:any) => {
        if (a.student.name < b.student.name) {
            return -1;
        }
        if (a.student.name > b.student.name) {
            return 1;
        }
        return 0;
      });
    }
    else{
      this.data.details.sort((a:any, b:any) => {
        if (a.student.numInternatRoom < b.student.numInternatRoom) {
            return -1;
        }
        if (a.student.numInternatRoom > b.student.numInternatRoom) {
            return 1;
        }
        return 0;
      });
    }
    localStorage.setItem("KNU-21-call-order", JSON.stringify(true))
  }
  setAllRetards(bool:boolean){
    for(let k in this.data.details){
      this.data.details[k]['isRetarted'] = bool;
    }
  }
}
