import {
  HttpRequest,
  HttpInterceptor,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let currentToken = sessionStorage.getItem("USER-knu21");
    if (
      currentToken
    ) {
      if (request.url.includes('/api/Login')) {
        console.log(request.url)
        return next.handle(request);
      }
      else{
            
            request = request.clone({
              setHeaders: {
                Authorization: `Bearer ${currentToken}`
              }
            });
            return next.handle(request);
      }    
    }
    return next.handle(request);
  }
}
