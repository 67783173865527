import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthenticationService } from "../services/authentication.service";
import {jwtDecode} from 'jwt-decode';
import { Location } from '@angular/common';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root"
})
export class StaffGuard implements CanActivate {
  constructor(private authService: AuthenticationService,private location: Location) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      let token:any = sessionStorage.getItem("USER-knu21");
      let decodedToken:any = jwtDecode(token);
      
      if (token && !this.authService.isTokenExpired(token) && 
        (decodedToken['Role'] == "SURVEILLANT_"+environment.establishment 
        || decodedToken['Role'] == "CPE_"+environment.establishment 
        || decodedToken['Role'] == "DIRECTEUR_"+environment.establishment)
      ) {
      return true;
    } else {
      return false;
    }
  }
}
