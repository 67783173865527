<div class="page-container" [hidden]="!displayedColumns">
      <div>
        <div class="title-container">
          <div class="title"> 
            <h1 *ngIf="title">{{title}}</h1>
          </div>
        </div>
        <div *ngIf="type == 'asbences' ||  type == 'remarques' " class="mat-mini-icon-container"  (click)="$event.stopPropagation(); create()">
          <mat-icon>add_circle_outline</mat-icon>
          <span *ngIf="type == 'asbences'" class="mobile-hide"> Prévenir d'une absence</span>
          <span *ngIf="type == 'remarques' " class="mobile-hide"> Ajouter une remarque </span>
        </div>
          <div class="table-row">
            <div  class="table-container{{typeTable}} col-12" [hidden] = "!dataSource" >
              <table mat-table [dataSource]="dataSource" matSort  matSortDisableClear matSortDirection="desc">
            
                <ng-container *ngFor ="let col of displayedColumns">
                  <ng-container matColumnDef="{{col}}">
  
                    <th mat-header-cell *matHeaderCellDef mat-sort-header id="{{col}}"> 
                        <ng-container *ngIf="col != 'Justificatif'">
                          <div class="flex-container">
                            <div class="col">
                              {{col}}
                            </div>
                            <div class="filter">
                              <input matInput [(ngModel)]="filter[col]" placeholder="filter sur {{col}}" #input (keyup)="$event.stopPropagation(); applyFilter($event)" (click)="$event.stopPropagation()">
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngIf="col == 'Justificatif' ">

                          <div class="filter">
                            <div class="col">
                              {{col}}
                            </div>
                            <input matInput [(ngModel)]="filter[col]" placeholder="filter sur {{col}}" #input (keyup)="$event.stopPropagation(); applyFilter($event)" (click)="$event.stopPropagation()">

                          </div>
                        
                        </ng-container>
                    </th>
  
                    <td mat-cell *matCellDef="let row"> 
                      <ng-container *ngIf="col !='actions'"> 
                        <ng-container *ngIf="col !='Justificatif'">
                          {{row[col]}}
                        </ng-container>
                        <ng-container *ngIf="col =='Justificatif'">
                          <ng-container *ngIf ="row[col] == null"> 
                            <form enctype="multipart/form-data">
                              <input #inputFile type="file" [hidden]="true" (change)="onFileSelected($event,row)" accept=".pdf">
                              <button type="submit" class="btn" (click)="inputFile.click()">Envoyer un justificatif (pdf)</button>
                            </form>
                          </ng-container>
                          <ng-container *ngIf ="row[col] != null">
                            
                            <div class="openFile" (click)="openPdfInNewTab(row[col])"> Justificatif </div>
                          
                          </ng-container>
                        </ng-container>
                      </ng-container>  
                    </td>
                  </ng-container>
                </ng-container>
            
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
                
                <!-- Row shown when there is no matching data. -->
                <tr class="mat-row" *matNoDataRow>
                  <td class="mat-cell" colspan="4">Aucune données corresponds au(x) filtre(s)</td>
                </tr>
              </table>
            </div>    
          </div>
          <div class="progress-container" [hidden] = "dataSource">
            <mat-progress-spinner mode="indeterminate" diameter="50" color="#1e5995" strokeWidth="4"></mat-progress-spinner>
          </div>
      </div>  
  </div>
  <div class ="loader" [hidden]="displayedColumns">
    <mat-spinner></mat-spinner>
  </div>