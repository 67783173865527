import { Component } from '@angular/core';
import { MessagerieService } from 'src/app/services/messagerie.service';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { MessagerieDetailDialogComponent } from 'src/app/template-staff/messagerie-detail-dialog/messagerie-detail-dialog.component';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { jwtDecode } from 'jwt-decode';
import { forkJoin } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-msg',
  templateUrl: './msg.component.html',
  styleUrls: ['./msg.component.css']
})
export class MsgComponent {
  contact_list= new Array();
  searchedTerm= "";
  data_recep:any;
  columns_recep:any;
  
  data_send:any;
  columns_send:any;
  
  data_noread:any;
  columns_noread:any;
  

  constructor( 
    private messagerie : MessagerieService,
    private dataService : DataStaffService,
    private dialog:  MatDialog,
    private msgInfo: MsgInfoService,
  
  ){
    let token:any = sessionStorage.getItem("USER-knu21");
    let decodedToken:any = jwtDecode(token);

    this.dataService.getStaffs().subscribe((data:any)=>{
      for(let k in data.data){
        if(data.data[k].numen != decodedToken.id )
        this.contact_list.push({
          id : data.data[k].numen,
          nom : data.data[k].name,
          prenom : data.data[k].firstname,
        });
      }    
    },(error)=>{
      this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
    })

    this.init();
  }
  init(searchedTerm = ""){
    this.data_recep=new Array();
    this.columns_recep=new Array();
    
    this.data_send=new Array();
    this.columns_send=new Array();
    
    this.data_noread=new Array();
    this.columns_noread=new Array();
    
    if(searchedTerm == ""){
      this.messagerie.getSend().subscribe((res:any)=>{
        let tmp = this.displayDataMessagerie(res.data, "send")
        this.data_send = tmp[0];
        this.columns_send = tmp[1];
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
      
      this.messagerie.getRecep().subscribe((res:any)=>{
        let array_recep = new Array();
        let array_noread = new Array();
        
        res.data.forEach((el:any) => {
          if(!el.isRead){
            array_noread.push(el);
          }
          else{
            array_recep.push(el);
          }
        });
  
        let tmp = this.displayDataMessagerie(array_noread, "recep")
        this.data_noread = tmp[0];
        this.columns_noread = tmp[1];
        
        tmp = this.displayDataMessagerie(array_recep, "recep")
        this.data_recep = tmp[0];
        this.columns_recep = tmp[1];
      },(error)=>{
        this.msgInfo.updateInfo({ msg : "Une erreur s'est produite. Si le problème persite merci de contacter le service technique", duration : 4000});
      })
    }
    else{
      // this.messagerie.getSearchTitle(searchedTerm).subscribe((res:any)=>{
      //   console.log(res);
      // })
      // this.messagerie.getSearchBody(searchedTerm).subscribe((res:any)=>{
      //   console.log(res);
      // })
    }

  }
  openMessagerie(to = null, subject = null) {
    const dialogRef = this.dialog.open(MessagerieDetailDialogComponent,{
      width: '350px',
      height: '80vh',
      data : {
        contact_list : this.contact_list,
        to : to,
        subject : subject,
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res){
        if(
          res.subject && res.subject != ''
          && res.body && res.body != ''
          && res.destinataires
        ){
          let params = {
            subject : res.subject,
            body : res.body,
            attachement: '',            
          }
          let subs_array = new Array()
          const observables = res.destinataires.map((el: any) => {
            return this.messagerie.send(el.id, params);
          });
          
          forkJoin(observables).subscribe(
            (results: any) => {
              this.init();
            },
            error => {
              // Gérer les erreurs si l'une des requêtes échoue
              console.error('Une erreur est survenue:', error);
            }
          );

        }
      }
    });
  }
  displayDataMessagerie(data:Array<any>, type:string = "recep"){
    let return_data = new Array();
    let colums = new Array();
    if(type == "recep"){
       colums = new Array(
        'Heure',
        'De',
        'Sujet',
        'Message',
        'actions'
      );
    }
    else{
       colums = new Array(
        'Heure',
        'A',
        'Sujet',
        'Message',
        'actions'
      );
    }
    
    
    if(data){
      for(let k = 0; k < data.length ; k++){
        let heure:any = this.formatDate(data[k].createDate,data[k].createHour);
        
        if(type == "recep"){
          return_data.push({
            Heure:  heure.dateStr,
            De:  data[k].idSender,
            Sujet: data[k].subject,
            Message : data[k].body,
            id : data[k].idMessage, 
          });
        }
        else{
          return_data.push({
            Heure:  heure.dateStr,
            A:  data[k].idRecipient,
            Sujet: data[k].subject,
            Message : data[k].body,
            id: data[k].idMessage,  
          });
        }
      }
      return_data.sort((a, b) => {
        if (a.Heure > b.Heure) {
            return -1;
        }
        if (a.Heure < b.Heure) {
            return 1;
        }
        return 0;
      });
    }

    return new Array(return_data,colums);
  
  }
  formatDate(dateNumber:number, timeNumber:number){

    const year = Math.floor(dateNumber / 10000);
    const month = Math.floor((dateNumber % 10000) / 100);
    const day = dateNumber % 100;
    
    const hours = Math.floor(timeNumber / 10000);
    const minutes = Math.floor((timeNumber % 10000) / 100);
    const seconds = timeNumber % 100;
    
    const formattedDate = `${year}/${('0' + month).slice(-2)}/${('0' + day).slice(-2)}`;
    const formattedTime = `${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}:${('0' + seconds).slice(-2)}`;
    

    const date = {
     date : new Date(year, month-1, day, hours, minutes, seconds),
     dateStr : formattedDate + " " + formattedTime,
    }  

    return date;
  }

  modify(res: any) {
    if(res.msg == "refreshMsg" ){
      this.init();
    }
    else if(res.msg == "refresh&sendMsg"){
      this.init();
      this.openMessagerie(res.data.to, res.data.subject);
    }
    else if(res.msg == "sendMsg"){
      this.openMessagerie(res.data.to, res.data.subject);
    }
  }
  

  searchMessage(){

    console.log(this.searchedTerm)
    this.init(this.searchedTerm);
  }
}
