<div *ngIf="selectables" class="flex-container">
  <h3 *ngIf= "title">{{title}}</h3>
  <div *ngFor = "let s of selectables | keepOrder">
        <ng-container *ngIf="!isString(s.value) && s.value && s.key ">
          <mat-form-field class="field" *ngIf="s.key != 'Liste Etudiants' ">
            <mat-label>{{s.key}}</mat-label>
            <mat-select [(ngModel)]="ret_data[s.key+'']" >
              <mat-option *ngFor="let v of s.value | keyvalue" [value]="v.key">
                {{v.value}}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field *ngIf="s.key == 'Liste Etudiants' ">
            <mat-label>{{s.key}}</mat-label>
            <mat-select [(ngModel)]="ret_data[s.key+'']" multiple (selectionChange)="preventDefault($event);" (keydown)="preventDefault($event);" (keyup)="preventDefault($event);">
              <input type="text" class="searchStudent"  (keydown)="filter(s.key+'', $event)" placeholder="rechercher un etudiant">
              <mat-option *ngFor="let v of filtered[s.key+''] " [value]="v.id">
                {{v.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        

        </ng-container>
        
          <mat-form-field class="field" *ngIf="isString(s.value) && s.value == 'date' && s.key ">
              <input matInput [ngxMatDatetimePicker]="$any(picker)" placeholder="Date {{s.key}}" (change)="changeDate($event, s.key)" [(ngModel)]="ret_data[s.key+'']"> 
              <mat-datepicker-toggle matSuffix [for]="$any(picker)"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker >
              </ngx-mat-datetime-picker>
           </mat-form-field>          
          
            
          <mat-form-field class="field" *ngIf="isString(s.value) && s.value == 'text' && s.key ">
            <mat-label>{{s.key}} </mat-label>
            <input [(ngModel)]="ret_data[s.key+'']"  matInput>            
          </mat-form-field>
          <mat-form-field class="field" *ngIf="isString(s.value) && s.value == 'textarea' && s.key ">
            <mat-label>{{s.key}} </mat-label>
            <textarea [(ngModel)]="ret_data[s.key+'']" rows="8" cols="30" matInput></textarea>
          </mat-form-field>
          <mat-form-field class="field" *ngIf="isString(s.value) && s.value == 'text|create' &&  data.type == 'create'  && s.key">
            <mat-label>{{s.key}} </mat-label>
            <input [(ngModel)]="ret_data[s.key+'']"  matInput>            
          </mat-form-field>
          <div class="field" *ngIf="isString(s.value) && s.value == 'boolean'  && s.key">
            <mat-slide-toggle [(ngModel)]="ret_data[s.key+'']" >{{s.key}}</mat-slide-toggle>
          </div>
          <div>
            <input type="file"  *ngIf="isString(s.value) && s.value == 'file'"  (change)="onFileSelected($event, s.key)" />
          </div>
    </div>
    <div>
        <button [hidden]="!check(ret_data)" [mat-dialog-close]="ret_data">Valider</button>
        <span [hidden]="check(ret_data)" class="small-font"> Merci de remplir tous les champs pour valider</span>
    </div>
</div>
