import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {jwtDecode} from 'jwt-decode';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private tokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  subs= new Array();
  
  constructor(
    private http: HttpClient,
    private router : Router
  ) {}
  
  
  updateUser(data:any) {
    this.userSubject.next(data);
  }
  
  getUserLogin(){
    return this.userSubject.getValue();
  }
  getToken(user:any, pwd:any): Observable<any> {    
    this.subs.push(this.login(user, pwd).subscribe(
      (data)=>{
        const token = data.token;
        const decoded:any = jwtDecode(token);
        localStorage.setItem("USER-knu21", token);
        if (decoded) {
        }
      
      },
      (error)=>{
        localStorage.removeItem("USER-knu21");
        this.router.navigateByUrl('login');
      }
    ));
    return this.tokenSubject.asObservable();

  }
  
  
  login(login: string, password: string): Observable<any> {
    
    return this.http.post(`${environment.apiUrl}/api/Login`, {
      login : login,
      password : password,
      idEstablishement: environment.establishment,
      mode : 1,
    });
  }

  loginAdmin(login: string, password: string): Observable<any> {
    sessionStorage.setItem('log', login);
    sessionStorage.setItem('pwd', password);
    
    return this.http.post(`${environment.apiUrl}/api/Login`, {
      login : login,
      password : password,
      idEstablishement: environment.establishment,
      mode : 1,
    });
  }
  
  
  
  getTokenExpirationDate(token: string): any {
    const decoded:any = jwtDecode(token);

    if (decoded.exp === undefined) {
      return null;
    }
    
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  isTokenExpired(token: string): boolean {
    if (!token) {
      return true;
    }
    
    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }
  ngOnDestroy() {
    for(let k in this.subs){
      this.subs[k].unsubscribe();
    }
  }
}
