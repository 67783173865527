<div class="wrapper">
  <div class="col-sm-9 col-md-7 col-lg-5 mx-auto card-signin">
    <div class="card ">
      
      <div class="card-body">

        <div class="header-container">
          <div class="header-card">
            <div class="logotype">
              <div class="logo">
                <img src="/assets/img/logo.png" alt="logo">
              </div>
              <div class="nom">
                Canu
              </div>
              <div class="nom">
                Staff
              </div>
            </div>
          </div>
        </div>

        <form [formGroup]="loginForm" (keyup.enter)="onSubmit()">
          <div class="form-group">
            <label>Identifiant</label>
            <input
              type="text"
              formControlName="email"
              class="form-control"
            />
            
          </div>
          <div class="form-group">
            <label>Mot de Passe</label>
            <input *ngIf ="!showPassword"
              type="password"
              formControlName="password"
              [(ngModel)]="password2"
              class="form-control"
            />
            <input *ngIf ="showPassword"
              type="text"
              formControlName="password"
              [(ngModel)]="password2"
              class="form-control"
            />
          </div>
          <div class="showPassword">
            <mat-slide-toggle [checked]="showPassword" (change)="showPassword = !showPassword"> {{showPassword ? 'Masquer' : 'Afficher'}} le mot de passe</mat-slide-toggle>
          </div>

        </form>
        <div class="form-group d-flex flex-column">
          <button class="btn btn-primary" (click)="onSubmit()">Se connecter</button>
          <div *ngIf="error" class="error">Erreur de connection, vérifier votre login et mot de passe</div>
        </div>
      </div>
    </div>
  </div>

</div>
