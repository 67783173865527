import { Component, AfterViewInit, ViewChild,SimpleChanges,OnChanges,Input,Output,EventEmitter } from '@angular/core';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import { DataStaffService } from 'src/app/services/data-staff.service';
import { StudentDialogStaffComponent } from '../student-dialog-staff/student-dialog-staff.component';
import { CallDialogStaffComponent } from '../call-dialog-staff/call-dialog-staff.component';
import { MsgInfoService } from 'src/app/services/msg-info.service';
import { MessageDetailDialogComponent } from '../message-detail-dialog/message-detail-dialog.component';
import { MessagerieService } from 'src/app/services/messagerie.service';
@Component({
  selector: 'app-table-staff',
  templateUrl: './table-staff.component.html',
  styleUrls: ['./table-staff.component.css']
})
export class TableStaffComponent {

  @ViewChild(MatSort) sort!: MatSort;
  @Input() displayedColumns!: any;
  @Input() data!: any;
  @Input() title!: string;
  @Input() type!: string;
  @Input() selectables!: any;
  @Input() typeTable!: any;
  @Output() tableChanged = new EventEmitter<any>();

  
  dataSource: any;
  filter :any;
  today_ts =  Date.now();
  startDay:any;
  endDay:any;
 
  showMenu = false;
  hide_filter = true;
  constructor(    
    private dialog:  MatDialog,
    private dataStaffService : DataStaffService,
    private messagerie : MessagerieService,
    private msgInfo : MsgInfoService,
  ){
    this.startDay = this.getMidnightTimestamp();
    this.endDay = this.getEndOfDayTimestamp();
  }
  
  private generateFilter(){
    this.filter = {};
    if(this.displayedColumns && this.displayedColumns.length>0){
      for(let i=0 ;i<this.displayedColumns.length;i++){
        if(this.displayedColumns[i] !="action" && this.displayedColumns[i]!="checked"){
          this.filter[this.displayedColumns[i]] = "";
        }
      }
    }
  }
  getMidnightTimestamp() {
    const now = new Date();
    const midnight = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
    return midnight.getTime();
  }

  getEndOfDayTimestamp() {
    const now = new Date();
    const endOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
    return endOfDay.getTime();
  }

  formatData(data:any){
    if(this.type =="affectation"){
      let ret = new Array();
      for(let k in data){
        let fin = this.convertToTimestamp(data[k].Fin);
        let debut = this.convertToTimestamp(data[k].Début);
        if(
          (debut > this.startDay && debut < this.endDay ) || (fin > this.startDay && fin < this.endDay )
        ){
          ret.push(data[k]);
        }
      }
      return ret;
    }
    else{
      return data;
    }
  
  }
  ngOnChanges(changes: SimpleChanges): void {
    if(this.data){
      this.generateFilter();
      this.data = this.formatData(this.data);
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.sort = this.sort;
      this.dataSource.filterPredicate = this.createFilter();
    }

  }
  applyFilter(event: Event) {
    this.dataSource.filter = this.filter;
  }
  createFilter() {
    let filterFunction = function (data: any, filter:any): boolean {
      let isFilterSet = false;
      for (const col in filter) {
        if (filter[col].toString() !== '') {
          isFilterSet = true;
        }
      }
      
      
      let nameSearch = () => {
        let found = true;
        if (isFilterSet) {
          for (const col in filter) {
            if(col !="actions" && col !="checked"){
              filter[col].trim().toLowerCase().split(' ').forEach((word:any) => {
                if (!(data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet)) {
                  found = false
                }
              });
            }
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }
  openStudentDialog(id:string) {
    const dialogRef = this.dialog.open(StudentDialogStaffComponent,{
      data: {
        id : id,
      },
      width:"100vw"
    });
  }
  openCallDialog(id:string, classe:string, debut:string ) {
    const dialogRef = this.dialog.open(CallDialogStaffComponent,{
      data: {
        id : id,
        classe : classe,
        date : debut.split(" ")[0], 
        heure : debut.split(" ")[1]
      }
    });
    dialogRef.afterClosed().subscribe((ret:any) => {
      let ret_array = new Array();
      if(ret){
        for(let k in ret.details){
          ret_array.push(
            {
              "ineStudent": ret.details[k].iDstudent,
              "isPresent": ret.details[k].isPresent,
              "isRetarded": ret.details[k].isRetarted,
              "info": ''
            }
          )
        }        
        this.dataStaffService.callStart(ret.id).subscribe((res1:any)=>{
          this.msgInfo.updateInfo({msg :"Mise à jour des présences", duration : -1})
          this.dataStaffService.updateCall(ret.id, ret_array).subscribe((res2:any)=>{
            this.dataStaffService.callEnd(ret.id).subscribe((res3:any)=>{
              this.msgInfo.updateInfo({msg:null});
            })
          })
        })
      }
      
    });
  }
  viewMsg(data:any){
    let dialogRef = this.dialog.open(MessageDetailDialogComponent,{
      width: '550px',
      height: '80vh',
      data: data,
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res && res.to){
        if(this.type =="MsgNoRead"){
          this.tableChanged.emit({msg:'refresh&sendMsg', data : res});
        }
        else{
          this.tableChanged.emit({msg:'sendMsg', data : res});
        }
      }
      else if(this.type =="MsgNoRead"){
        this.messagerie.setRead(data.id).subscribe((res)=>{
          console.log("read",res)
          this.tableChanged.emit({msg:'refreshMsg', data : res});
        })
      }
    });
  }
  timeCheck(row:any){
    if(row.Fin && this.type =="affectation" ){
      if(row.callDetail.isClose){
        return "green-alert";
      }
      else{
        if(this.convertToTimestamp(row.Fin) < this.today_ts){
          return "red-alert";
        }
        else if(this.convertToTimestamp(row.Début) < this.today_ts){
          return "orange-alert";
        }
        else{
          return "blue-alert";
        }
      }
      
    }
    else{
      return "";
    }   
  }
  
  callCheck(row:any){
    if(row.Fin){
      if(this.convertToTimestamp(row.Fin) + 3600*1000 < this.today_ts ){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      return false;
    }
  }
  
  convertToTimestamp(dateString:string) {
    const date = new Date(dateString);
    return date.getTime();
  }
}
