
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-messagerie-detail-dialog',
  templateUrl: './messagerie-detail-dialog.component.html',
  styleUrls: ['./messagerie-detail-dialog.component.css']
})
export class MessagerieDetailDialogComponent {
  selectedDestinataires=new Array();
  displayedDestinataire:any;
  ret_data= {
    destinataires  : <any>[],
    subject : '',
    body : '',
  };
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef <MessagerieDetailDialogComponent>,

  ){    
    console.log(this.data.contact_list)
    this.displayedDestinataire = this.data.contact_list;
    
    if(data.to){
      this.displayedDestinataire.forEach((el:any) => {
        if(data.to == el.id){
          this.ret_data.destinataires.push(el);
        }
      });
      console.log(this.ret_data.destinataires)
    }

    if(data.subject){
      this.ret_data.subject = data.subject;
    }
  }
  filter(e:any){
    console.log(e.value);
    this.displayedDestinataire = this.data.contact_list.filter((item:any) => item.nom.toLowerCase().includes(e.value.toLowerCase()) || item.prenom.toLowerCase().includes(e.value.toLowerCase()));
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  check(d:any){
    let bool = true;
    for(let k in d){
      if(d[k]=== ''){
        bool = false;
        break;
      }
    }
    return bool;
  }

}
