<mat-icon class="exitDialog" (click)="onNoClick()">clear</mat-icon>
  
  <div class="container-speformfield">
    
    <mat-form-field class="speformfield">
      <mat-label>Destinataires</mat-label>
      <mat-select multiple [(ngModel)]="ret_data['destinataires']">
        <input type="text" (keyup)="filter($event.target)" class="round-input" placeholder="rechercher">
        <mat-option *ngFor="let d of displayedDestinataire" [value]="d">
          {{d.nom}} {{d.prenom}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field  class="speformfield">
      <mat-label>Sujet</mat-label>
      <input [(ngModel)]="ret_data['subject']" matInput >
    </mat-form-field>
  
    <mat-form-field  class="speformfield body">
      <mat-label>message</mat-label>
      <textarea [(ngModel)]="ret_data['body']" matInput ></textarea>
    </mat-form-field>
  </div>  
  
  <div style = "text-align: center;">
    <button class="btn-send" [hidden]="!check(ret_data)" [mat-dialog-close]="ret_data">
      <mat-icon>send</mat-icon>  
      <span>Envoyer</span>
    </button>
    <span [hidden]="check(ret_data)" class="small-font"> Merci de remplir tous les champs pour envoyer </span>
  </div>
  <div class="title">Destinataires</div>
  <div class="container-destinataire">
    <div *ngFor="let s of ret_data['destinataires']" style="margin-left: 10px;">
      {{s.nom}} {{s.prenom}}
    </div>
  </div>

  