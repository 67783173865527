import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header-staff',
  templateUrl: './header-staff.component.html',
  styleUrls: ['./header-staff.component.css']
})

export class HeaderStaffComponent {
  showMenu = false;
  constructor(    
    private router : Router,
  ){}
  
  logout(){
    sessionStorage.removeItem('USER-knu21');
    this.router.navigate(['login']);
  }
}
